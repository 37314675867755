export const environment = {
  production: false,
  panel: "kClient_",
  domain: "kanhaexch.com",
  baseUrl: "https://api.kanhaexch.com",
  pollers: {
      oddsDataList: 10000,
      oddsData: 300,
      scoresData: 1000,
  },
  noDataMsg: 'No Data Found',
  guestEnable: true,
  stakes: { "stake_name_1": "100", "stake_name_2": "5000", "stake_name_3": "500", "stake_name_4": "25000", "stake_name_5": "50000", "stake_name_6": "100000", "stake_name_7": "500000", "stake_name_8": "1000000", "stake_amount_1": 100, "stake_amount_2": 5000, "stake_amount_3": 500, "stake_amount_4": 25000, "stake_amount_5": 50000, "stake_amount_6": 100000, "stake_amount_7": 500000, "stake_amount_8": 1000000 },
  horseRaceJockeyImgPrefix: 'https://content-cache.cdnbf.net/feeds_images/Horses/SilkColours/',
  host: {
      domain: '',
      name: '',
      dName: '',
      ext: '',
      theme: '',
      email: '',
      pusher: {
          key: '',
          cluster: ''
      },
      ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
      guestEnable: true,
      signupEnable: false,
      signupIframeUrl: '',
      forgotPasswordIframeUrl: '',
      social: {
          isShow: false,
          name: '',
          yt_url: '',
          fb_url: '',
          ig_url: '',
          ww_url: ''
      }
  },
  hosts: [
      {
          domain: 'localhost',
          name: 'localhost',
          dName: 'LocalHost',
          ext: '.com',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          guestEnable: true,
          signupEnable: false,
          signupIframeUrl: '',
          forgotPasswordIframeUrl: '',
          social: {
              isShow: false,
              name: 'LocalHost',
              yt_url: '',
              fb_url: '',
              ig_url: '',
              ww_url: ''
          }
      },
      {
          domain: 'pdmclient.pages.dev',
          name: 'pdmclient.pages',
          dName: 'PDM-Client',
          ext: '.com',
          theme: 'yellow-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          guestEnable: true,
          signupEnable: false,
          signupIframeUrl: '',
          forgotPasswordIframeUrl: '',
          social: {
              isShow: false,
              name: 'PDM-Client',
              yt_url: '',
              fb_url: '',
              ig_url: '',
              ww_url: ''
          }
      },
      {
          domain: 'kanhaexch.com',
          name: 'kanhaexch',
          dName: 'KanhaExch',
          ext: '.com',
          theme: 'orange-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          guestEnable: true,
          signupEnable: false,
          signupIframeUrl: '',
          forgotPasswordIframeUrl: '',
          social: {
              isShow: false,
              name: 'KanhaExch',
              yt_url: '',
              fb_url: '',
              ig_url: '',
              ww_url: ''
          }
      },
      {
          domain: 'chotu99.com',
          name: 'chotu99',
          dName: 'Chotu99',
          ext: '.com',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          guestEnable: true,
          signupEnable: false,
          signupIframeUrl: '',
          forgotPasswordIframeUrl: '',
          social: {
              isShow: false,
              name: 'Chotu99',
              yt_url: '',
              fb_url: '',
              ig_url: '',
              ww_url: ''
          }
      },
      {
          domain: 'my11circle.club',
          name: 'my11circle',
          dName: 'My11circle',
          ext: '.club',
          theme: 'darkcyan-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          guestEnable: true,
          signupEnable: false,
          signupIframeUrl: '',
          forgotPasswordIframeUrl: '',
          social: {
              isShow: false,
              name: 'My11circle',
              yt_url: '',
              fb_url: '',
              ig_url: '',
              ww_url: ''
          }
      },
      {
          domain: 'my11gamer.club',
          name: 'my11gamer',
          dName: 'My11gamer',
          ext: '.club',
          theme: 'green-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          guestEnable: true,
          signupEnable: false,
          signupIframeUrl: '',
          forgotPasswordIframeUrl: '',
          social: {
              isShow: false,
              name: 'My11gamer',
              yt_url: '',
              fb_url: '',
              ig_url: '',
              ww_url: ''
          }
      },
      {
          domain: 'pdm999.com',
          name: 'pdm999',
          dName: 'PDM999',
          ext: '.com',
          theme: 'yellow-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          guestEnable: true,
          signupEnable: false,
          signupIframeUrl: '',
          forgotPasswordIframeUrl: '',
          social: {
              isShow: false,
              name: 'PDM999',
              yt_url: '',
              fb_url: '',
              ig_url: '',
              ww_url: ''
          }
      },
      {
          domain: 'chotuexch.bet',
          name: 'chotuexch',
          dName: 'ChotuExch',
          ext: '.bet',
          theme: 'yellow-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          guestEnable: true,
          signupEnable: false,
          signupIframeUrl: '',
          forgotPasswordIframeUrl: '',
          social: {
              isShow: false,
              name: 'ChotuExch',
              yt_url: '',
              fb_url: '',
              ig_url: '',
              ww_url: ''
          }
      },
      {
          domain: 'worldmarckets.in',
          name: 'worldmarckets',
          dName: 'worldmarckets',
          ext: '.in',
          theme: 'yellow-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          guestEnable: true,
          signupEnable: false,
          signupIframeUrl: '',
          forgotPasswordIframeUrl: '',
          social: {
              isShow: false,
              name: 'worldmarckets',
              yt_url: '',
              fb_url: '',
              ig_url: '',
              ww_url: ''
          }
      },
      {
          domain: 'ignite999.com',
          name: 'ignite999',
          dName: 'ignite999',
          ext: '.com',
          theme: 'green-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          guestEnable: true,
          signupEnable: false,
          signupIframeUrl: '',
          forgotPasswordIframeUrl: '',
          social: {
              isShow: false,
              name: 'ignite999',
              yt_url: '',
              fb_url: '',
              ig_url: '',
              ww_url: ''
          }
      },
      {
          domain: '7starexch.com',
          name: '7starexch',
          dName: '7starexch',
          ext: '.com',
          theme: 'darkcyan-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          guestEnable: true,
          signupEnable: false,
          signupIframeUrl: '',
          forgotPasswordIframeUrl: '',
          social: {
              isShow: false,
              name: '7starexch',
              yt_url: '',
              fb_url: '',
              ig_url: '',
              ww_url: ''
          }
      },
      {
          domain: 'rudra999.com',
          name: 'rudra999',
          dName: 'rudra999',
          ext: '.com',
          theme: 'darkcyan-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          guestEnable: true,
          signupEnable: false,
          signupIframeUrl: '',
          forgotPasswordIframeUrl: '',
          social: {
              isShow: false,
              name: 'rudra999',
              yt_url: '',
              fb_url: '',
              ig_url: '',
              ww_url: ''
          }
      },
      {
          domain: 'junoonexchange.com',
          name: 'junoonexchange',
          dName: 'junoonexchange',
          ext: '.com',
          theme: 'yellow-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          guestEnable: true,
          signupEnable: false,
          signupIframeUrl: 'https://netflixexch.com/signup/12',
          forgotPasswordIframeUrl: 'https://netflixexch.com/reset/12',
          social: {
              isShow: true,
              name: 'junoonexchange',
              yt_url: 'https://www.youtube.com/@junoonexchange',
              fb_url: 'https://www.facebook.com/junoonexchangeofficial',
              ig_url: 'https://www.instagram.com/junoonexchange',
              ww_url: 'https://wa.link/junoonexchange'
          }
      },
      {
          domain: 'cheetah999.com',
          name: 'cheetah999',
          dName: 'cheetah999',
          ext: '.com',
          theme: 'yellow-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          guestEnable: true,
          signupEnable: false,
          signupIframeUrl: '',
          forgotPasswordIframeUrl: '',
          social: {
              isShow: false,
              name: 'cheetah999',
              yt_url: '',
              fb_url: '',
              ig_url: '',
              ww_url: ''
          }
      },
      {
          domain: 'dynamo.bet',
          name: 'dynamo',
          dName: 'dynamo',
          ext: '.bet',
          theme: 'yellow-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          guestEnable: true,
          signupEnable: false,
          signupIframeUrl: '',
          forgotPasswordIframeUrl: '',
          social: {
              isShow: false,
              name: 'dynamo',
              yt_url: '',
              fb_url: '',
              ig_url: '',
              ww_url: ''
          }
      },
      {
          domain: 'crex999.com',
          name: 'crex999',
          dName: 'crex999',
          ext: '.com',
          theme: 'yellow-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          guestEnable: true,
          signupEnable: false,
          signupIframeUrl: '',
          forgotPasswordIframeUrl: '',
          social: {
              isShow: false,
              name: 'crex999',
              yt_url: '',
              fb_url: '',
              ig_url: '',
              ww_url: ''
          }
      },
  ],
};
